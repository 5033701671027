import { useEffect, useState } from "react";

export const useFetch = (url, requestOptions = null) => {
  const [state, setState] = useState({
    data: null,
    isLoading: true,
    hasError: null,
  })
  const getFetch = async () => {
    let resp = {};

    setState({
      ...state,
      isLoading: true,
    })

    if (requestOptions == null) {
      resp = await fetch(url);
    } else {
      resp = await fetch(url, requestOptions);
    }

    const data = await resp.json();

    setState({
      data,
      isLoading: false,
      hasError: null,
    })
  }

  useEffect(() => {
    getFetch();
  }, [url])

  return {
    data: state.data,
    isLoading: state.isLoading,
    hasError: state.hasError,
  };
}
