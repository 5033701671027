import { useFetch } from '../hooks';
import { Loading } from './Loading';


export default function Result({ country, brand, model, year, transmission, state, onResetForm, setSend }) {
  const apiKey = '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';
  const prompt = `Dame el rango unicamente, sin texto, moneda dependiendo del país \n Precio automovil Honda Civic nuevo 2016 transmision manual en Mexico\nrespuesta:\n$225,000 - $290,000 Pesos Mexicanos \nPrecio automovil ${brand} ${model} ${state} ${year} con transmision ${transmission} en ${country}\nRespuesta: `;
  const maxTokens = 500;
  const { data, isLoading, hasError } = useFetch('https://api.openai.com/v1/engines/text-davinci-003/completions',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        prompt,
        max_tokens: maxTokens,
      })
    }
  );
  console.log({ data, isLoading, hasError });

  return (
    <>
      {
        isLoading ?
          <Loading /> :
          <>
            <button onClick={() => { setSend(false); onResetForm() }} className='btn-secondary'>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" fill="none" viewBox="0 0 10 18">
                <path stroke="#000" d="M9 1 1 9l8 8" />
              </svg>
              Regresar
            </button>
            <div className='result-container'>
              El Rango del automóvil con <strong>{brand}</strong> <strong>{model}</strong> <strong>{year}</strong> <strong>{state}</strong> con transmisión  <strong>{transmission}</strong> en <strong>{country}</strong> es:
              <div className='result'>{data.choices[0].text}</div>
            </div>
          </>

      }
    </>
  )
}
