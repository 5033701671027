import { useEffect, useState } from "react"

export const Form = ({ country, brand, model, year, transmission, state, onInputChange, onResetForm, setSend }) => {
  const [countryData, setCountryData] = useState('');
  const [brandData, setBrandData] = useState('');
  const [modelData, setModelData] = useState('');
  const [yearData, setYearData] = useState('');
  const [transmissionData, setTransmissiontData] = useState('');
  const [stateData, setStateData] = useState('');

  const validation = () => {
    (country !== '') ? setCountryData(true) : setCountryData(false);
    (brand !== '') ? setBrandData(true) : setBrandData(false);
    (model !== '') ? setModelData(true) : setModelData(false);
    (year !== null) ? setYearData(true) : setYearData(false);
    (transmission !== '') ? setTransmissiontData(true) : setTransmissiontData(false);
    (state !== '') ? setStateData(true) : setStateData(false);
  }

  const onResetInputs = () => {
    setCountryData('');
    setBrandData('');
    setModelData('');
    setYearData('');
    setTransmissiontData('');
    setStateData('');
  }

  useEffect(() => {
    if (countryData && brandData && modelData && yearData && transmissionData && stateData) {
      console.log(transmission, state)
      setSend(true);
    }
  }, [countryData, brandData, modelData, yearData, transmissionData, stateData]);

  return (
    <>
      <h2 className="box">Ingresa los datos para conocer el precio</h2>
      <div className="mini-form">
        <label>País:</label>
        <div>
          <input type="text"
            className="form-control"
            placeholder="Ciudad"
            name="country"
            value={country}
            onChange={onInputChange}
          />
          {countryData !== '' && !countryData &&
            <div class="error-validation">Por favor completa este campo.</div>}
        </div>

        <label>Marca:</label>
        <div>
          <input type="text"
            className="form-control"
            placeholder="Marca"
            name="brand"
            value={brand}
            onChange={onInputChange}
          />
          {brandData !== '' && !brandData &&
            <div class="error-validation">Por favor completa este campo.</div>}
        </div>

        <label>Modelo:</label>
        <div>
          <input type="text"
            className="form-control"
            placeholder="Modelo"
            name="model"
            value={model}
            onChange={onInputChange}
          />
          {modelData !== '' && !modelData &&
            <div class="error-validation">Por favor completa este campo.</div>}
        </div>

        <label>Año:</label>
        <div>
          <input type="number"
            className="form-control"
            placeholder="Año"
            name="year"
            value={year || ''}
            onChange={onInputChange}
          />
          {yearData !== '' && !yearData &&
            <div class="error-validation">Por favor completa este campo.</div>}
        </div>

        <label>Transmisión:</label>
        <div>
          <input
            type="radio"
            id="manual"
            name="transmission"
            value="Estandar"
            checked={transmission === 'Estandar'}
            onChange={onInputChange} />
          <label htmlFor="manual">Estandar</label>

          <input
            type="radio"
            id="automatic"
            name="transmission"
            value="Automatico"
            checked={transmission === 'Automatico'}
            onChange={onInputChange} />
          <label htmlFor="automatic">Automático</label>
          {transmissionData !== '' && !transmissionData &&
            <><br /><div class="error-validation">Por favor selecciona uno.</div></>}
        </div>

        <label>Estado:</label>
        <div>
          <input
            type="radio"
            id="used"
            name="state"
            value="usado"
            checked={state === 'usado'}
            onChange={onInputChange} />
          <label htmlFor="used">Usado</label>

          <input
            type="radio"
            id="new"
            name="state"
            value="nuevo"
            checked={state === 'nuevo'}
            onChange={onInputChange} />
          <label htmlFor="new">Nuevo</label>
          {stateData !== '' && !stateData &&
            <><br /><div class="error-validation">Por favor selecciona uno.</div></>}
        </div>
      </div>
      <button onClick={() => { onResetForm(); onResetInputs() }} className="btn-primary">Limpiar</button>
      <button onClick={() => { validation() }} className="btn-primary">Buscar</button>
    </>
  )
}
