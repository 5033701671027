import { useState } from "react";
import { Form } from "./Components/Form";
import { useForm } from "./hooks";
import Result from "./Components/Result";

export const App = () => {
  const [send, setSend] = useState(false);
  const { country, brand, model, year, transmission, state, onInputChange, onResetForm } = useForm({
    country: '',
    brand: '',
    model: '',
    year: null,
    transmission: '',
    state: '',
  });

  return (
    <div className="container">
      <h1>Cotizador de Autos con AI</h1>
      {
        !send ?
          <Form
            country={country}
            brand={brand}
            model={model}
            year={year}
            transmission={transmission}
            state={state}
            onInputChange={onInputChange}
            onResetForm={onResetForm}
            setSend={setSend}
          /> :
          <Result
            country={country}
            brand={brand}
            model={model}
            year={year}
            transmission={transmission}
            state={state}
            onResetForm={onResetForm}
            setSend={setSend}
          />
      }
    </div>
  )
}